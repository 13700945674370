import React from 'react';

function GoogleformIcon(props: React.SVGAttributes<{}>) {
    return (
        <svg width="22" height="27" viewBox="0 0 22 27" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g filter="url(#filter0_d_683_286)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M17.5348 1.05859C19.1813 1.05859 20.5161 2.39337 20.5161 4.03991V21.9597C20.5161 23.6062 19.1813 24.941 17.5348 24.941H4.46615C2.81961 24.941 1.48483 23.6062 1.48483 21.9597V4.03991C1.48483 4.02924 1.48488 4.01859 1.485 4.00795V6.21724L4.14938 3.63794L6.62345 1.05864L4.46614 1.05859H17.5348Z"
                    fill="#9747FF"
                />
                <g filter="url(#filter1_d_683_286)">
                    <path d="M6.69907 3.16755L6.62268 1.05859L1.48423 6.2172L3.66627 6.25631C5.37591 6.28695 6.76096 4.87635 6.69907 3.16755Z" fill="#C090FF" />
                </g>
                <g filter="url(#filter2_d_683_286)">
                    <rect width="9.13502" height="1.91059" rx="0.496886" transform="matrix(-1 0 0 1 14.2358 9.65625)" fill="white" />
                    <rect width="9.13502" height="1.91059" rx="0.496886" transform="matrix(-1 0 0 1 14.2358 13.668)" fill="white" />
                    <rect width="9.13502" height="1.91059" rx="0.496886" transform="matrix(-1 0 0 1 14.2358 17.6807)" fill="white" />
                    <rect width="1.90313" height="1.91059" rx="0.951564" transform="matrix(-1 0 0 1 16.7104 9.65625)" fill="white" />
                    <rect width="1.90313" height="1.91059" rx="0.951564" transform="matrix(-1 0 0 1 16.7104 13.668)" fill="white" />
                    <rect width="1.90313" height="1.91059" rx="0.951564" transform="matrix(-1 0 0 1 16.7104 17.6807)" fill="white" />
                </g>
            </g>
            <defs>
                <filter id="filter0_d_683_286" x="-0.00628439" y="0.561707" width="21.0193" height="25.8704" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="-0.496886" dy="0.496886" />
                    <feGaussianBlur stdDeviation="0.496886" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_683_286" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_683_286" result="shape" />
                </filter>
                <filter id="filter1_d_683_286" x="-0.00628439" y="0.561707" width="7.20434" height="7.18579" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="-0.496886" dy="0.496886" />
                    <feGaussianBlur stdDeviation="0.496886" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_683_286" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_683_286" result="shape" />
                </filter>
                <filter id="filter2_d_683_286" x="4.10681" y="9.15936" width="13.5974" height="11.9221" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="0.496886" />
                    <feGaussianBlur stdDeviation="0.496886" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_683_286" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_683_286" result="shape" />
                </filter>
            </defs>
        </svg>
    );
}

export default GoogleformIcon;
