import React from 'react';

function AnalyticsIcon(props: React.SVGAttributes<{}>) {
    return (
        <svg width="24" height="24" viewBox="0 0 1920 1920" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g fillRule="evenodd" clipRule="evenodd" stroke="none" strokeWidth="1">
                <path fillRule="evenodd" clipRule="evenodd" d="M746.667 106.667V1493.33H1173.33V106.667H746.667ZM1056 224H864V1376H1056V224ZM106.667 533.333H533.333V1493.33H106.667V533.333ZM224 650.667H416V1376H224V650.667Z" />
                <path d="M1920 1706.67H0V1824H1920V1706.67Z" />
                <path fillRule="evenodd" clipRule="evenodd" d="M1386.67 746.667H1813.33V1493.33H1386.67V746.667ZM1504 864H1696V1376H1504V864Z" />
            </g>
        </svg>
    );
}

export default AnalyticsIcon;
