import React, { useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import AnchorLink from '@app/components/common/links/anchor-link';
import { useModal } from '@app/components/modal-views/context';
import { useGetUserStatusQuery } from '@app/store/users/api';
import { LinkDetailsResponse } from '@app/store/formUrl/types';
import { toast } from 'react-toastify';
import { ToastId } from '@app/constants/toast-id';

import CopyIcon from '@app/components/icons/copy-icon';
import ThreeDotIcon from '@app/components/icons/threedot';
import GoogleformIcon from '@app/components/icons/googleform';
import EditIcon from '@app/components/icons/edit';
import RemoveIcon from '@app/components/icons/remove';
import ShareIcon from '@app/components/icons/share';
import AnalyticsIcon from '@app/components/icons/analytics/analytics';
import QrCodeIcon from '@app/components/icons/qrcode';

interface CustomizeUrlCardProps extends LinkDetailsResponse {
    hideActions?: boolean;
    isLandingPage?: boolean;
}

export default function CustomizeUrlCard({ id, destinationUrl, mappedDomain, mappedPath, isLandingPage, hideActions = false }: CustomizeUrlCardProps) {
    const customizeUrl = `${window.location.protocol}//${mappedDomain}/to/${mappedPath}`;
    const { data: user } = useGetUserStatusQuery();
    const router = useRouter();
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);
    const { openModal } = useModal();
    const handleCopyCustomizeLink = () => {
        navigator.clipboard.writeText(customizeUrl);
        toast.success('Copied to clipboard', { toastId: ToastId.SUCCESS_TOAST });
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
            setIsDropdownOpen(false);
        }
    };

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    useEffect(() => {
        if (isDropdownOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isDropdownOpen]);

    return (
        <div className={`mb-4 flex w-full flex-col items-center justify-between gap-4 rounded-lg border bg-white p-4 ${isLandingPage && 'max-w-[734px]'} ${!hideActions ? 'shadow-lg' : ''} md:flex-row`}>
            <div className="flex w-full min-w-0 items-center gap-4">
                <div className="flex-shrink-0 rounded-full border-2 border-neutral-300 p-2 text-purple-600">
                    <GoogleformIcon className="h-7 w-7" />
                </div>
                <div className="flex w-full min-w-0 flex-col gap-1 overflow-hidden text-left font-medium text-primary">
                    <AnchorLink target="_blank" href={customizeUrl} className="body2 max-w-full overflow-hidden text-ellipsis whitespace-nowrap font-medium hover:underline">
                        {customizeUrl}
                    </AnchorLink>
                    <p className="body3 w-full min-w-0 overflow-hidden text-ellipsis whitespace-nowrap font-normal text-gray-500">{destinationUrl}</p>
                </div>
            </div>
            {!hideActions && (
                <div className="mt-2 flex w-full items-center justify-end gap-2 sm:ml-2 sm:mt-0 md:w-auto">
                    <button onClick={handleCopyCustomizeLink} className="z-30 flex max-w-xs items-center space-x-2 rounded-lg border bg-white px-2 py-2 text-sm font-medium text-neutral-900 hover:bg-gray-100 hover:text-blue-600 sm:w-auto">
                        <CopyIcon className="h-4 w-4" />
                        <span>Copy</span>
                    </button>
                    {user && (
                        <>
                            <button className="z-30 flex max-w-xs items-center rounded-lg border bg-white p-1 hover:bg-gray-100 hover:text-blue-600 sm:w-auto" onClick={() => router.push(`/dashboard/qr/${id}`)}>
                                <QrCodeIcon className="h-7 w-7" />
                            </button>
                            <div className="relative" ref={dropdownRef}>
                                <button onClick={toggleDropdown} className="z-30 flex max-w-xs items-center rounded-lg border bg-white p-1 hover:bg-gray-100 hover:text-blue-600 sm:w-auto">
                                    <ThreeDotIcon />
                                </button>
                                {isDropdownOpen && (
                                    <div className="absolute right-0 z-50 mt-2 w-40 rounded-md border bg-white shadow-md">
                                        <ul className="divide-y divide-gray-200 text-sm text-gray-800 hover:cursor-pointer">
                                            <li className="flex items-center gap-2 px-4 py-2 hover:bg-gray-100" onClick={() => router.push(`/dashboard/link/${id}`)}>
                                                <EditIcon className="h-4 w-4 text-neutral-800" />
                                                <span className="text-neutral-800">Edit</span>
                                            </li>
                                            <li className="flex items-center gap-2 px-4 py-2 hover:bg-gray-100" onClick={() => router.push(`/dashboard/analytics/${id}`)}>
                                                <AnalyticsIcon className="h-4 w-4 text-neutral-800" />
                                                <span className="text-neutral-800">Analytics</span>
                                            </li>
                                            <li className="flex items-center gap-2 px-4 py-2 hover:bg-gray-100" onClick={() => openModal('DELETE_MODAL', { form_id: id })}>
                                                <RemoveIcon className="h-4 w-4 text-neutral-800" />
                                                <span className="text-neutral-800">Delete</span>
                                            </li>
                                            <li className="flex items-center gap-2 px-4 py-2 hover:bg-gray-100" onClick={() => openModal('SHARE_MODAL', { customizeUrl: customizeUrl })}>
                                                <ShareIcon className="h-4 w-4 fill-current text-neutral-800" />
                                                <span className="text-neutral-800">Share</span>
                                            </li>
                                        </ul>
                                    </div>
                                )}
                            </div>
                        </>
                    )}
                </div>
            )}
        </div>
    );
}
